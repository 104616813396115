.modules-wrapper {
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 24px);
  padding-bottom: 1rem;
}

.modules-wrapper .icon--danger .primary {
  fill: var(--danger) !important;
}

.modules-wrapper .icon--danger .secondary {
  fill: var(--light) !important;
}

.modules-wrapper .icon--warning .primary {
  fill: var(--warning) !important;
}

.modules-wrapper .icon--warning .secondary {
  fill: var(--light) !important;
}

@media screen and (max-width: 767px) {
  .modules-wrapper {
    height: calc(100vh - 80px);
  }
}
