@media (min-width: 768px) {
  .w-md-50 {
    width: 50% !important;
  }
}

[class*='icon'] {
  width: 1.5rem;
}

[class*='icon'] .primary {
  fill: #f8f9fa !important;
}

[class*='icon'] .secondary,
.dropdown-item [class*='icon'] .primary {
  fill: #6c757d !important;
}

.active [class*='icon'] .secondary {
  fill: #0056b2 !important;
}

.dropdown-item.active [class*='icon'] .primary,
.dropdown-item.active [class*='icon'] .secondary,
.btn-primary [class*='icon'] .primary,
.btn-primary [class*='icon'] .secondary {
  fill: #f8f9fa !important;
}

.module-window-frame {
  width: 100%;
  height: calc(100vh - 24px);
}

@media screen and (max-width: 767px) {
  body:not(#module-body) {
    overflow: hidden;
  }
  .module-window-frame {
    height: calc(100vh - 80px);
  }
}

.logo {
  position: relative;
  width: auto;
}

@media (min-width: 540px) {
  .logo {
    width: 270px;
  }

  .logo:after {
    position: absolute;
    content: 'Portal';
    top: 50%;
    transform: translateY(-50%);
  }
}
