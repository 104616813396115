.fv__formContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(90, 92, 105);
  background-image: linear-gradient(rgb(90, 92, 105) 10%, rgb(55, 56, 64) 100%);
  background-size: cover;
  margin: 0px;
}

.fv__formSection {
  position: relative;
  margin-bottom: 20px;
  background-color: var(--form-color);
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}

.fv__sectionHeader {
  color: var(--deepSquidInk);
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 500;
}

.fv__sectionBody {
  margin-bottom: 30px;
}

.fv__sectionFooter {
  font-size: 14px;
  color: var(--grey);
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}

.fv__button {
  width: 100%;
  background-color: var(--primary);
  border-color: var(--primary);
  border-radius: 3px;
  min-width: 153px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-image: none;
  color: var(--button-color);
  text-transform: uppercase;
  padding: 14px 0;
  letter-spacing: 1.1px;
  border: none;
}

@media only screen and (max-width: 599px) {
  .fv__formSection {
    width: 100%;
    box-sizing: border-box;
    padding: 35px 25px;
    box-shadow: none;
    border-radius: 0;
    min-width: auto;
  }

  .fv__sectionFooter {
    flex-wrap: wrap;
  }
}
